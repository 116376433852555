import React from 'react';
import '../index.css';
import imgNosotros from '../images/nosotros.webp';
import iconsMision from '../icons/mision_opt.png';
import iconsVision from '../icons/vision_opt.png';
import hitoUno from '../icons/hito-1.png';
import hitoDos from '../icons/hito-2.png';
import hitoTres from '../icons/hito-3.png';
import Navbar from '../components/Navbar';
import Footer from '../components/footer';
import Certificaciones from '../components/Certificaciones';
import DescripcionNosotros from '../components/DescripcionNosotros';
import "animate.css";
import WOW from 'wowjs';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {FormattedMessage} from 'react-intl';

class Nosotros extends React.Component{
    state = { value:0, previous:0, checked: false };

    constructor(props) {
        super(props);
        this.state = {isToggleOn: true};
    
        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
      }
    
      handleClick() {
        this.setState(prevState => ({
          isToggleOn: !prevState.isToggleOn
        }));
    }

    componentDidMount() {
        new WOW.WOW({
          live: false
      }).init();
    }
    render() {
        return (
            <>
            <Navbar titlelight="menu.about" title="title.empty" titlelightDefaul="Nosotros" titleDefaul=" " image={imgNosotros} />

            <div className='container mx-auto px-44 py-32 xs-py-20 md:py-28 md:px-14 lg:px-44 xs-px-4'>
                <DescripcionNosotros></DescripcionNosotros>
            </div>

            <div className="bg-cover bg-center img-fondo">
                <div className="container mx-auto py-32 xs-py-20 md:py-12 lg:py-12 xl:py-12 2xl:py-32">
                    <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
                        <div className="grid-flow-col transition duration-500 ease-in-out transform w-8/12 overflow-hidden m-auto wow animate__animated  animate__fadeInLeft">
                            <img src={iconsMision} alt="vision" className="m-auto xs-w-40"/>
                            <p className="Ma-med text-4xl text-center text-white xs-text-size-2">
                                <FormattedMessage
                                    id="about.misionTitle" 
                                    defaultMessage="MISIÓN" 
                                />
                            </p>
                            <p className="text-justify Ma-light mt-8 text-mision text-white xs-text-size-2">
                                <FormattedMessage
                                    id="about.misionDescrip" 
                                    defaultMessage="Crear soluciones de software que ayuden a nuestros clientes alcanzar sus objetivos de negocio." 
                                />
                                <span className='invisible'>nuestros clientes alcanzar sus objetivos de negocio.</span>
                            </p>
               
                        </div>
                        <div className="grid-flow-col transition duration-500 ease-in-out transform w-8/12 overflow-hidden m-auto wow animate__animated  animate__fadeInRight">
                            <img src={iconsVision} alt="vision" className="m-auto xs-w-40"/>
                            <p className="Ma-med text-4xl text-center text-white xs-text-size-2">
                                <FormattedMessage
                                    id="about.visionTitle" 
                                    defaultMessage="VISIÓN" 
                                />
                            </p>
                            <p className="text-justify Ma-light mt-8 text-mision text-white xs-text-size-2">
                                <FormattedMessage
                                    id="about.visionDescrip" 
                                    defaultMessage="Ofrecer e implementar servicios de software a escala global, convirtiéndonos en el socio estratégico de grandes organizaciones en diversos sectores." 
                                />
                            </p>
                         
                        </div>
                    </div>
                </div>
            </div>
        
            <div className="container mx-auto px-44 py-20 pb-24 xs-py-20 xs-px-4">
                <Certificaciones></Certificaciones>
                <div className="py-28 Ma-light text-xl font-medium txt-silver Ma-book text-justify wow animate__animated  animate__fadeIn"  data-wow-delay="0.3s">
                    <FormattedMessage
                        id="about.certifiHistoryOne" 
                        defaultMessage="En el 2011, MICRODATA obtuvo la" 
                    />
                    <span className='font-bold Ma-med'>
                    <FormattedMessage
                        id="about.certifiHistoryTwo" 
                        defaultMessage=" certificación en la Norma Técnica Peruana" 
                    />
                    </span>
                    <FormattedMessage
                        id="about.certifiHistoryThree" 
                        defaultMessage=" NTP 291.100:2009 – Procesos de desarrollo y mantenimiento de software y en el 2013 en la norma ISO/IEC 29110 – Ciclo de vida de desarrollo de software 
                        ambos acreditados por el Instituto para la Calidad de la Pontificia Universidad Católica del Perú. Desde el 2018 a la fecha somos una empresa homologada como proveedores de servicios acreditados 
                        por la corporación HODELPE S.A. En el 2020 renovamos nuestra certificación ISO/IEC 29110 - Ciclo de vida de desarrollo de software esta vez acreditados por la empresa Global Colombia Certificación S.A.S." 
                    />
                </div>
                <div className="grid place-content-center Ma-light">
                    <a href="/servicios" className="mx-auto bg-md text-white py-3 px-10 boton-silver hover:shadow-lg text-xl font-medium tracking-wide" >
                        <FormattedMessage
                            id="app.buttonOne" 
                            defaultMessage="NUESTROS SERVICIOS" 
                        />
                    </a>
                </div>
            </div>
            
                    <div className="pb-10 ">
                        <div className="container mx-auto py-12 pb-4 wow animate__animated  animate__fadeInLeft">
                            <h1 className="text-center text-4xl mb-8 Ma-light font-normal uppercase text-azul"><span className='font-bold Ma-med'>
                                <FormattedMessage
                                    id="about.hitoTitleOne" 
                                    defaultMessage="HITOS" 
                                />
                                </span>
                                <FormattedMessage
                                    id="about.hitoTitleTwo" 
                                    defaultMessage=" IMPORTANTES" 
                                />
                            </h1>

                            <VerticalTimeline>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ color: '#a2acbd' }}
                                    date="2021 - 2022"
                                    iconStyle={{ background: '#005A91', color: '#fff' }}
                                    icon={<img src={hitoUno} alt="hitoUno" className="w-3/6 m-auto pt-2"/>}
                                >
                                    <p className="Ma-light">
                                        <FormattedMessage
                                            id="hito.one" 
                                            defaultMessage="Certificación de Homologación de Proveedores otorgado por la Corporación HODELPE. 
                                            Trabajo remoto basado en el método OKR (Objectives and Key Results). MICRODATA LAB - proyectos de innovación 
                                            y desarrollo bajo la metodología Design Thinking" 
                                        />
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ color: '#a2acbd' }}
                                    date="2020"
                                    iconStyle={{ background: '#2E9D49', color: '#fff' }}
                                    icon={<img src={hitoUno} alt="hitoUno" className="w-3/6 m-auto pt-2"/>}
                                >
                                    <p className="Ma-light">
                                        <FormattedMessage
                                            id="hito.two" 
                                            defaultMessage="Certificación de Homologación de Proveedores otorgado por la Corporación HODELPE.
                                            Trabajo remoto basado en el método OKR (Objectives and Key Results). Implementación del estándar 
                                            ISO/IEC 29110 (Perfil Intermedio)" 
                                        />
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ color: '#a2acbd' }}
                                    
                                    date="2019"
                                    iconStyle={{ background: '#005A91', color: '#fff' }}
                                    icon={<img src={hitoUno} alt="hitoUno" className="w-3/6 m-auto pt-2"/>}
                                >
                                    <p className="Ma-light">
                                        <FormattedMessage
                                            id="hito.three" 
                                            defaultMessage="Certificación de Homologación de Proveedores otorgado por la Corporación
                                            HODELPE. Adopción del estándar ISO/IEC 29110 (Perfil Intermedio)" 
                                        />
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ color: '#a2acbd' }}
                                    date="2018"
                                    iconStyle={{ background: '#2E9D49', color: '#fff' }}
                                    icon={<img src={hitoDos} alt="hitoDos" className="w-4/6 m-auto pt-3"/>}
                                >
                                    <p className="Ma-light">
                                        <FormattedMessage
                                            id="hito.for" 
                                            defaultMessage="Adopción de buenas prácticas para planificación y seguimiento proyectos 
                                            de TI. Estandarización de procesos de negocio." 
                                        />
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--education"
                                    contentStyle={{ color: '#a2acbd' }}
                                    
                                    date="2016 - 2017"
                                    iconStyle={{ background: '#005A91', color: '#fff' }}
                                    icon={<img src={hitoDos} alt="hitoDos" className="w-4/6 m-auto pt-3"/>}
                                >
                                    <p className="Ma-light">
                                        <FormattedMessage
                                            id="hito.five" 
                                            defaultMessage="Adopción del estándar ISO/IEC 29119 – Pruebas de Software para fortalecer el área que QA" 
                                        />
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--education"
                                    contentStyle={{ color: '#a2acbd' }}
                                    date="2015"
                                    iconStyle={{ background: '#2E9D49', color: '#fff' }}
                                    icon={<img src={hitoTres} alt="hitoTres" className="w-4/6 m-auto pt-3"/>}
                                >
                                    <p className="Ma-light">
                                        <FormattedMessage
                                            id="hito.six" 
                                            defaultMessage="Mejoras en la Implementación de ISO/IEC 29110 (Perfil básico)" 
                                        />
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--education"
                                    contentStyle={{ color: '#a2acbd' }}
                                    
                                    date="2014"
                                    iconStyle={{ background: '#005A91', color: '#fff' }}
                                    icon={<img src={hitoTres} alt="hitoTres" className="w-4/6 m-auto pt-3"/>}
                                >
                                    <p className="Ma-light">
                                        <FormattedMessage
                                            id="hito.seven" 
                                            defaultMessage="Aplicación de principios y procesos de desarrollo ágil de software tales como Scrum y Kanban" 
                                        />
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--education"
                                    contentStyle={{ color: '#a2acbd' }}
                                    date="2013"
                                    iconStyle={{ background: '#2E9D49', color: '#fff' }}
                                    icon={<img src={hitoUno} alt="hitoUno" className="w-3/6 m-auto pt-2"/>}
                                >
                                    <p className="Ma-light">
                                        <FormattedMessage
                                            id="hito.eight" 
                                            defaultMessage="Certificación de NTP-ISO/IEC:29110 otorgado por el Instituto 
                                            de Calidad de la PUCP – (Perfil Básico) primera empresa certificada en Perú" 
                                        />
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--education"
                                    contentStyle={{ color: '#a2acbd' }}
                                    
                                    date="2012"
                                    iconStyle={{ background: '#005A91', color: '#fff' }}
                                    icon={<img src={hitoTres} alt="hitoTres" className="w-4/6 m-auto pt-3"/>}
                                >
                                    <p className="Ma-light">
                                        <FormattedMessage
                                            id="hito.nine" 
                                            defaultMessage="Implementación del estándar 
                                            NTP-ISO/IEC:29110 (Perfil Básico) – Ciclo de vida de desarrollo de software." 
                                        />
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--education"
                                    contentStyle={{ color: '#a2acbd' }}
                                    date="2011"
                                    iconStyle={{ background: '#2E9D49', color: '#fff' }}
                                    icon={<img src={hitoUno} alt="hitoUno" className="w-3/6 m-auto pt-2"/>}
                                >
                                    <p className="Ma-light">
                                        <FormattedMessage
                                            id="hito.ten" 
                                            defaultMessage="Certificación en el estándar NTP-291.100 (MoProSoft) otorgado por el Instituto de Calidad de la PUCP, primera empresa certificada en Arequipa-Perú" 
                                        />
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--education"
                                    contentStyle={{ color: '#a2acbd' }}
                                    
                                    date="2010"
                                    iconStyle={{ background: '#005A91', color: '#fff' }}
                                    icon={<img src={hitoTres} alt="hitoTres" className="w-4/6 m-auto pt-3"/>}
                                >
                                    <p className="Ma-light">
                                        <FormattedMessage
                                            id="hito.eleven" 
                                            defaultMessage="Implementación del estándar 
                                            NTP-291.100-4:2009 y NTP-291.100-2:2009 (MoProSoft) – Desarrollo y mantenimiento de software" 
                                        />
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--education"
                                    contentStyle={{ color: '#a2acbd' }}
                                    date="2008 - 2009"
                                    iconStyle={{ background: '#2E9D49', color: '#fff' }}
                                    icon={<img src={hitoDos} alt="hitoDos" className="w-4/6 m-auto pt-3"/>}
                                >
                                    <p className="Ma-light">
                                        <FormattedMessage
                                            id="hito.twelve" 
                                            defaultMessage="Aplicación de modelos codificar-corregir y cascada en los procesos de desarrollo de software" 
                                        />
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    iconStyle={{ background: '#005A91', color: '#fff' }}
                                    icon={<img src={hitoTres} alt="hitoTres" className="w-4/6 m-auto pt-3"/>}
                                />
                            </VerticalTimeline>
                        </div>
                    </div>

            <Footer />
        </>
        )
    }
}


export default Nosotros;