import React, { useContext } from 'react';
import logoBlanco from '../images/logo-b.webp';
import logoColor from '../images/logo-c.webp';
import BanUS from '../images/Iconos/bandera-us_mini.jpg';
import BanPE from '../images/Iconos/bandera-peru.png';

import { FormattedMessage } from 'react-intl';
import { langContext } from '../context/langContext';

export default function MenuPrincipal({ fixed }) {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    const [navbar, setNavbar] = React.useState(false);
    const idioma = useContext(langContext);

    const changeBackground = () =>{
      if (window.scrollY >= 80){
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    };
    
    //console.log(idioma.establecerLenguaje());

    window.addEventListener('scroll', changeBackground);
    const style = {
      display: 'flex'
    };

    return (
      <>
        <nav className={navbar ? 'navbar active' : 'navbar'}>
          <div className="container mx-auto xs-d-block md:block lg:flex xl:flex 2xl:flex flex items-center" style={style}>
            <div className='xs-flex md:flex lg:flex'>
              <a href="/">
                  <img src={navbar ? logoColor : logoBlanco} alt="microdata" className='w-1/4 xs-w-50 md:w-5/12 lg:w-8/12 xl:w-5/12 2xl:w-4/12'></img>
              </a>
              <button
                className={
                  navbar ? 'h-8 border1 m-auto text-blue-600 cursor-pointer text-xl leading-none px-3 py-1 rounded bg-transparent block lg:hidden outline-none focus:outline-none' 
                  : 
                  'h-8 border2 m-auto text-white cursor-pointer text-xl leading-none px-3 py-1 rounded bg-transparent block lg:hidden outline-none focus:outline-none'}
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <ion-icon name="menu-outline"></ion-icon>
              </button>
            </div>
            <div
              className={
                "lg:flex flex-grow items-center" +
                (navbarOpen ? " flex" : " hidden")
              }
              id="example-navbar-danger"
            >
              <ul className={"flex flex-col lg:flex-row list-none lg:ml-auto" +
                (navbar ? " menu-b" : " menu")} data-animation="center">
                <li className="nav-item px-3 py-2">
                  <a
                    className="Ma-book text-xl lg:text-2xl flex items-center text-xs leading-snug text-white hover:opacity-75"
                    href="/">
                    <span>
                      <FormattedMessage 
                        id="menu.home"
                        defaultMessage="Home"
                        />
                    </span>
                  </a>
                </li>
                <li className="nav-item px-3 py-2">
                  <a
                    className="Ma-book text-xl lg:text-2xl flex items-center text-xs leading-snug text-white hover:opacity-75"
                    href="/servicios">
                    <span>
                    <FormattedMessage 
                        id="menu.services"
                        defaultMessage="Servicios"
                        />
                    </span>
                  </a>
                </li>
                <li className="nav-item px-3 py-2">
                  <a
                    className="Ma-book text-xl lg:text-2xl flex items-center text-xs leading-snug text-white hover:opacity-75"
                    href="/proceso">
                    <span>
                    <FormattedMessage 
                        id="menu.processes"
                        defaultMessage="Procesos"
                        />
                    </span>
                  </a>
                </li>
                <li className="nav-item px-3 py-2">
                  <a
                    className="Ma-book text-xl lg:text-2xl flex items-center text-xs leading-snug text-white hover:opacity-75"
                    href="/nosotros">
                    <span>
                    <FormattedMessage 
                        id="menu.about"
                        defaultMessage="Nosotros"
                        />
                    </span>
                  </a>
                </li>
                <li className="nav-item px-3 py-2">
                  <a
                    className="Ma-book text-xl lg:text-2xl flex items-center text-xs leading-snug text-white hover:opacity-75"
                    href="/contacto">
                    <span>
                    <FormattedMessage 
                        id="menu.contact"
                        defaultMessage="Contacto"
                        />
                    </span>
                  </a>
                </li>
                <li className="nav-item px-3 py-3">
                  <div className='inline-flex space-x-4'>
                    <button onClick={() => idioma.establecerLenguaje('es-MX')} className='flex-1' style={{width:25 + 'px'}}><img src={BanPE} alt="microdata"/></button>
                    <button onClick={() => idioma.establecerLenguaje('en-US')} className='flex-1' style={{width:25 + 'px'}}><img src={BanUS} alt="microdata"/></button>
                  </div>
                  
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </>
    );

  }